<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? $t("workflow.add_workflow") : $t("workflow.adit_workflow") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Type Alarm -->
          <validation-provider
            #default="validationContext"
            name="alarm_type"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('types_alarms.title')"
              label-for="alarm_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_type_alarm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_type_alarm"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="alarm_status"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('workflow.alarm_status')"
              label-for="alarm_status"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.status"
                :options="options"
                multiple
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Unit -->
          <validation-provider
            #default="validationContext"
            name="unit"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('unit.title', 2)"
              label-for="unit"
              :state="getValidationState(validationContext)"
            >
              <!-- <v-select
                v-model="itemData.id_unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="unitOptions"
                :clearable="false"
                multiple
                :reduce="(val) => val.value"
                input-id="id_unit"

              /> -->

              <select-paginate
                v-model="itemData.id_unit"
                :items="unitOptions"
                input-id="id_unit"
                :multiple="true"
                @option:deselected="deselected"
                @option:selected="selected"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Command -->
          <validation-provider
            #default="validationContext"
            name="command"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('command.title')"
              label-for="command"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_command"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="commandOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_command"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Camera -->
          <validation-provider
            #default="validationContext"
            name="camera"
            rules="required"
            v-if="itemData.id_command == 1 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('Cameras')"
              label-for="camera"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_camera"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cameraOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                multiple
                input-id="id_camera"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- users -->
          <validation-provider
            #default="validationContext"
            name="users"
            rules="required"
            v-if="itemData.id_command == 5 || itemData.id_command == 6 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('Users')"
              label-for="users"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_user"
                dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_user"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- phones -->
          <validation-provider
            #default="validationContext"
            name="phone"
            rules="required"
            v-if="itemData.id_command == 5 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.phone_number')"
              label-for="phone"
              :state="getValidationState(validationContext)"
            >
              <b-form-tags
                v-model="itemData.phone"
                tag-variant="primary"
                input-id="phone"
                placeholder=""
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- emial -->
          <validation-provider
            #default="validationContext"
            name="emial"
            rules="required|email"
            v-if="itemData.id_command == 6 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('users.email')"
              label-for="emial"
              :state="getValidationState(validationContext)"
            >
              <b-form-tags
                v-model="itemData.email"
                tag-variant="primary"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="emial"
                placeholder=""
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- active -->
          <validation-provider name="frequency">
            <b-form-checkbox
              id="frequency"
              v-model="itemData.active"
              name="frequency"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t("workflow.frequency") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- days -->
          <validation-provider
            #default="validationContext"
            name="days"
            rules="required"
            v-if="itemData.active == 1 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('workflow.days')"
              label-for="days"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.selectDays"
                :options="days"
                :clearable="false"
                multiple
                input-id="days"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- start time-->
          <validation-provider
            #default="validationContext"
            name="start time"
            rules="required"
            v-if="itemData.active == 1 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('workflow.start_time')"
              label-for="start_time"
              :state="getValidationState(validationContext)"
            >
              <b-form-timepicker
                input-id="start_time"
                v-model="itemData.startTime"
                placeholder=""
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end time-->
          <validation-provider
            #default="validationContext"
            name="end time"
            rules="required"
            v-if="itemData.active == 1 ? true : false"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('workflow.end_time')"
              label-for="end_time"
              :state="getValidationState(validationContext)"
            >
              <b-form-timepicker
                input-id="end_time"
                v-model="itemData.endTime"
                placeholder=""
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormTimepicker,
  BFormTags
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import SelectPaginate from "../components/SelectPaginate.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
    BFormTags,
    SelectPaginate,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    workflowOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    commandOptions: {
      type: Array,
      required: true,
    },
    cameraOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      positive,
      status:[],
      selectDays:[],
      phone:[],
      email:[],
      options: [
          "waiting",
          "attending",
          "attended",
          "canceled"
      ],
      days: [
          "lunes",
          "martes",
          "miercoles",
          "jueves",
          "viernes",
          "sabado",
          "domingo"
      ],
      startTime:null,
      endTime:null,
      context: null,
    };
  },

  setup(props, { emit }) {
    const blankData = {
      id_type_alarm: null,
      id_unit: null,
      id_command: null,
      frequency: null
    };

    let unitsDeleted = [], unitsAdded = []

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const frequency = ref(null);
    const active = ref(null);

    const onSubmit = () => {
      let dispatch;
      let typeStatus={ waiting:0, attending:0, attended:0, canceled:0 }
      itemData.value.status.forEach(value=>{
        switch (value) {
          case 'waiting':
            typeStatus.waiting=1
            break
          case 'attending':
            typeStatus.attending=1
            break
          case 'attended':
            typeStatus.attended=1
            break
          case 'canceled':
            typeStatus.canceled=1
            break
        }
      })

      let data = {
        id_type_alarm: itemData.value.id_type_alarm,
        id_command: itemData.value.id_command,
        frequency: frequency.value,
        ...typeStatus,
      }

      if(itemData.value.active == 1){

        const starHour = itemData.value.startTime.substr(0,2)
        const startMin = itemData.value.startTime.substr(3,2)
        const endHour = itemData.value.endTime.substr(0,2)
        const endMin = itemData.value.endTime.substr(3,2)

        const typeDay = [];
        itemData.value.selectDays.forEach(value=>{
          switch (value) {
            case 'lunes':
              typeDay.push(0)
              break
            case 'martes':
              typeDay.push(1)
              break
            case 'miercoles':
              typeDay.push(2)
              break
            case 'jueves':
              typeDay.push(3)
              break
            case 'viernes':
              typeDay.push(4)
              break
            case 'sabado':
              typeDay.push(5)
              break
            case 'domingo':
              typeDay.push(6)
              break
          }
        });

        data['frequency'] = frequency.value = {
          active: true,
          days: typeDay,
          start:{
            hours: starHour,
            minutes: startMin
          },
          end:{
            hours: endHour,
            minutes: endMin
          },
        }
      }
      else{
        data['frequency'] = frequency.value = null
      }

      switch (itemData.value.id_command) {
        case 1:
          data['cameras'] = itemData.value.id_camera;
          if (props.isAdd) {
              dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
              };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;

        case 2:
          data['view'] = itemData.value.id_view;
          if (props.isAdd) {
            dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
              };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;

        case 3:
          if (props.isAdd) {
            dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
            };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;

        case 4:
          if (props.isAdd) {
            dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
            };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;

        case 5:
          data['users'] = itemData.value.id_user;
          data['phone_numbers'] = itemData.value.phone.map(Number)

          if(!data['phone_numbers'].includes(NaN)){
            if (props.isAdd) {
              dispatch = store.dispatch("workflow/add", data);
            }
            else {
              const req = {
                id: itemData.value.id,
                params: data
              };
              dispatch = store.dispatch("workflow/edit", req);
            }
          }
          else{
            toast({
              component: ToastificationContent,
              props: {
                title: 'Numero de telefono no valido',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return 0;
          }
          break;

        case 6:
          data['users'] = itemData.value.id_user;
          data['emails'] = itemData.value.email;

          if (props.isAdd) {
            dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
            };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;
        default:
          if (props.isAdd) {
            dispatch = store.dispatch("workflow/add", data);
          }
          else {
            const req = {
              id: itemData.value.id,
              params: data
            };
            dispatch = store.dispatch("workflow/edit", req);
          }
          break;
      }

      dispatch
        .then(async response => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });

            if(props.isAdd) {
              unitsAdded = unitsAdded.map(unit => ({
                id_alarm_workflow: response.data.id,
                id_unit: unit.id_unit
              }))
            }

            if (unitsAdded.length > 0)
                await store.dispatch("workflow/addUnits", unitsAdded);

            if (unitsDeleted.length > 0)
              await store.dispatch("workflow/deleteUnits", unitsDeleted);

            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      unitsDeleted = [], unitsAdded = []

      if (!props.isAdd) {
        itemData.value = props.itemEdit

        itemData.value.id_unit = props.itemEdit.units.map((value) => ({
          value: value.id,
          label: value.description,
        }))

        if(itemData.value.frequency != null){
          itemData.value.active = 1
          itemData.value.selectDays = []
          itemData.value.frequency.days.map(Number).forEach(day=>{
            switch (day) {
              case 0:
                itemData.value.selectDays.push('lunes')
                break
              case 1:
                itemData.value.selectDays.push('martes')
                break
              case 2:
                itemData.value.selectDays.push('miercoles')
                break
              case 3:
                itemData.value.selectDays.push('jueves')
                break
              case 4:
                itemData.value.selectDays.push('viernes')
                break
              case 5:
                itemData.value.selectDays.push('sabado')
                break
              case 6:
                itemData.value.selectDays.push('domingo')
                break
            }
          })

          itemData.value.startTime = itemData.value.frequency.start['hours']+':'+itemData.value.frequency.start['minutes']
          itemData.value.endTime = itemData.value.frequency.end['hours']+':'+itemData.value.frequency.end['minutes']
        }

        const json = itemData.value.data ? JSON.parse(itemData.value.data) : ''
        if(json.length != 0) {
          itemData.value.id_user = json['--userId'].map(Number);

          if(typeof json['--phoneNumber'] != "undefined"){
            itemData.value.phone = json['--phoneNumber']
          }
          if(typeof json['--email'] != "undefined"){
            itemData.value.email = json['--email']
          }
          if(typeof json['--cameraId'] != "undefined"){
            itemData.value.id_camera = json['--cameraId'];
          }
          if(typeof json['--viewId'] != "undefined"){
            itemData.value.id_view = json['--viewId']
          }

        }

        itemData.value.status = []
        if (props.itemEdit.waiting)
          itemData.value.status.push('waiting')

        if (props.itemEdit.attending)
          itemData.value.status.push('attending')

        if (props.itemEdit.attended)
          itemData.value.status.push('attended')

        if (props.itemEdit.canceled)
          itemData.value.status.push('canceled')
      }
    };

    const deselected = (val) => {
      let newUnit = unitsAdded.find(unit => unit.id_unit == val.value)
      if (newUnit)
        unitsAdded = unitsAdded.filter(unit => unit.id_unit !== val.value)
      else {
        unitsDeleted.push({
          id_alarm_workflow: props.itemEdit.id,
          id_unit: val.value
        })
      }
    }

    const selected = (val) => {
      let newUnit = unitsDeleted.find(unit => unit.id_unit == val[val.length - 1].value)
      if (newUnit)
        unitsDeleted = unitsDeleted.filter(unit => unit.id_unit !== val[val.length - 1].value)
      else {
        unitsAdded.push({
          id_alarm_workflow: props.itemEdit.id,
          id_unit: val[val.length - 1].value
        })
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      frequency,
      active,
      deselected,
      selected,
    };
  },
};
</script>