<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{ $t("filter") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label class="text-capitalize">{{ $tc("unit.title", 2) }}</label>
          <select-paginate
            :items="unitOptions"
            input-id="id_unit_filter"
            :multiple="true"
            @option:selected="optionSelect"
            @option:deselected="optionDeselect"
          />
        </b-col>
        <b-col cols="8" md="8" class="mt-auto">
          <b-button
            variant="secondary"
            class="float-right text-capitalize"
            @click="$emit('sync')"
          >
          {{ $t("access_devices.synchronize") }}

          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import SelectPaginate from "../components/SelectPaginate.vue";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    SelectPaginate,
  },
  props: {
    unitOptions: {
      type: Array,
      default: null,
    },
    unitFilter: {
      type: Array,
      default: null,
    },
  },
  setup(_, context){
    const selecteds = ref([])

    const optionSelect = (selected) => {
      selecteds.value = [];
      selected.forEach(element => {
        selecteds.value.push(element.value)
      });

      context.emit('update:unitFilter', selecteds.value);
    }

    const optionDeselect = (deselected) => {
      selecteds.value = selecteds.value.filter(values => values != deselected.value)

      context.emit('update:unitFilter', selecteds.value);
    }

    return {
      optionSelect,
      optionDeselect,
      selecteds,
    }
  }
};
</script>