import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('workflow/getAll')
  const workflowOptions = computed(() => store.getters['workflow/getSelectedItems'])

  store.dispatch('type_alarm/getAll')
  const typeAlarmOptions = computed(() => store.getters['type_alarm/getSelectedItems'])

  store.dispatch('unit/getAll', {with: 'router:id,id_tenant'})
  const unitOptions = computed(() => store.getters['unit/getSelectedItemsWithoutTenant'])

  store.dispatch('command/getAll')
  const commandOptions = computed(() => store.getters['command/getSelectedItems'])

  store.dispatch('cm_camera/getAll')
  const cameraOptions = computed(() => store.getters['cm_camera/getSelectedItems'])

  store.dispatch('user/getAll')
  const userOptions = computed(() => store.getters['user/getSelectedItems'])

  /*if (router.currentRoute.params.description)
      workflowFilter.value = parseInt(router.currentRoute.description)*/

  const tableColumns = [
    { label: 'types_alarms.title', key: 'type_alarm', sortable: true,
      formatter: (value, key, item) => {
        if(value) return value.map(item => { return item.description}).join(', ')
        return ""
      }
    },
    { label: 'alarm.waiting', key: 'waiting', sortable: true},
    { label: 'alarm.attending', key: 'attending', sortable: true},
    { label: 'alarm.attended', key: 'attended', sortable: true},
    { label: 'alarm.canceled', key: 'canceled', sortable: true},
    { label: 'Units', key: 'units_count', sortable: true, sortable: true },
    { label: 'command.title', key: 'command', sortable: true,
      formatter: (value, key, item) => {
        if(value) return value.map(item => { return item.name}).join(', ')
        return ""
      }
    },
    { label: 'workflow.data', key: 'data', sortable: true},
    { label: 'workflow.frequency', key: 'frequency', sortable: true, formatter: 'formatFrequency', thStyle: 'min-width: 200px'}
  ]

  if (ability.can('update', 'Workflow') == true || ability.can('delete', 'Workflow') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('workflow/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatFrequency = value => {
    if(value != null){
      let days = '';
      value.days.forEach(day=>{
        switch (day) {
          case 0:
            days += 'Lun '
            break
          case 1:
            days += 'Mar '
            break
          case 2:
            days += 'Mie '
            break
          case 3:
            days += 'Jue '
            break
          case 4:
            days += 'Vie '
            break
          case 5:
            days += 'Sab '
            break
          case 6:
            days += 'Dom '
            break
        }
      })
      return days +' de '+value.start.hours+':'+value.start.minutes+' a '+value.end.hours+':'+value.end.minutes
    }
    return ''
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    formatFrequency,
    typeAlarmOptions,
    unitOptions,
    workflowOptions,
    commandOptions,
    cameraOptions,
    userOptions,
    refetchData,
  }
}
